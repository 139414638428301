// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anteportada-js": () => import("./../../../src/pages/anteportada.js" /* webpackChunkName: "component---src-pages-anteportada-js" */),
  "component---src-pages-creditos-js": () => import("./../../../src/pages/creditos.js" /* webpackChunkName: "component---src-pages-creditos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pagina-1-js": () => import("./../../../src/pages/pagina-1.js" /* webpackChunkName: "component---src-pages-pagina-1-js" */),
  "component---src-pages-pagina-10-js": () => import("./../../../src/pages/pagina-10.js" /* webpackChunkName: "component---src-pages-pagina-10-js" */),
  "component---src-pages-pagina-11-js": () => import("./../../../src/pages/pagina-11.js" /* webpackChunkName: "component---src-pages-pagina-11-js" */),
  "component---src-pages-pagina-12-js": () => import("./../../../src/pages/pagina-12.js" /* webpackChunkName: "component---src-pages-pagina-12-js" */),
  "component---src-pages-pagina-13-js": () => import("./../../../src/pages/pagina-13.js" /* webpackChunkName: "component---src-pages-pagina-13-js" */),
  "component---src-pages-pagina-14-js": () => import("./../../../src/pages/pagina-14.js" /* webpackChunkName: "component---src-pages-pagina-14-js" */),
  "component---src-pages-pagina-15-js": () => import("./../../../src/pages/pagina-15.js" /* webpackChunkName: "component---src-pages-pagina-15-js" */),
  "component---src-pages-pagina-2-js": () => import("./../../../src/pages/pagina-2.js" /* webpackChunkName: "component---src-pages-pagina-2-js" */),
  "component---src-pages-pagina-3-js": () => import("./../../../src/pages/pagina-3.js" /* webpackChunkName: "component---src-pages-pagina-3-js" */),
  "component---src-pages-pagina-4-js": () => import("./../../../src/pages/pagina-4.js" /* webpackChunkName: "component---src-pages-pagina-4-js" */),
  "component---src-pages-pagina-5-js": () => import("./../../../src/pages/pagina-5.js" /* webpackChunkName: "component---src-pages-pagina-5-js" */),
  "component---src-pages-pagina-6-js": () => import("./../../../src/pages/pagina-6.js" /* webpackChunkName: "component---src-pages-pagina-6-js" */),
  "component---src-pages-pagina-7-js": () => import("./../../../src/pages/pagina-7.js" /* webpackChunkName: "component---src-pages-pagina-7-js" */),
  "component---src-pages-pagina-8-js": () => import("./../../../src/pages/pagina-8.js" /* webpackChunkName: "component---src-pages-pagina-8-js" */),
  "component---src-pages-pagina-9-js": () => import("./../../../src/pages/pagina-9.js" /* webpackChunkName: "component---src-pages-pagina-9-js" */)
}

